import { RootState } from 'types/root';
import { AddAchResp, TransactionsAchState, UseAchPayResp } from 'types/transaction-ach';
import { COPAS_PAYMENT_STATUS } from 'utils/constants';

export const transactionsAchTree = (state: RootState): TransactionsAchState => state.transactionsAch || {};

export const transactionsAchIsLoading = (state: RootState): boolean => transactionsAchTree(state).isLoading || false;

export const addAchResp = (state: RootState): AddAchResp => transactionsAchTree(state).addAchResp || {};

export const useAchPayResp = (state: RootState): UseAchPayResp => transactionsAchTree(state).useAchPayResp || {};

export const useAchPayFailed = (state: RootState): boolean =>
  useAchPayResp(state).status === COPAS_PAYMENT_STATUS.FAILED || false;

export const useAchPaySuccess = (state: RootState): boolean =>
  useAchPayResp(state).status === COPAS_PAYMENT_STATUS.SUCCESS || false;

export const useAchPayPending = (state: RootState): boolean =>
  useAchPayResp(state).status === COPAS_PAYMENT_STATUS.PENDING || false;
