import { AchDetails, CreditCard, DdDetails, PaymentMethodLimits, PaymentMethodsState } from 'types/payment-method';
import { RootState } from 'types/root';

export const paymentMethodsTree = (state: RootState): PaymentMethodsState => state.paymentMethods || {};

export const paymentMethodsIsLoading = (state: RootState): boolean => paymentMethodsTree(state).isLoading || false;

export const creditCardList = (state: RootState): CreditCard[] => {
  const ccList = [...paymentMethodsTree(state).creditCards];
  return ccList.sort((a, b) => (a.isDefault ? -1 : b.isDefault ? 1 : 0));
};

export const defaultCreditCard = (state: RootState): CreditCard =>
  creditCardList(state)?.find((card) => card.isDefault === true) || ({} as CreditCard);

export const achList = (state: RootState): AchDetails[] => paymentMethodsTree(state).ach || [];

export const directDebitList = (state: RootState): DdDetails[] => paymentMethodsTree(state).directDebits || [];

export const paymentMethodLimits = (state: RootState): PaymentMethodLimits =>
  paymentMethodsTree(state).paymentMethodLimits || { limits: { CREDIT_CARD: 2, DirectDebit: 1, ACH: 1 } };

export const paymentTypesIsLoading = (state: RootState): boolean =>
  paymentMethodsTree(state).isPaymentTypeLoading || false;

export const paymentMethodTypes = (state: RootState): string[] => paymentMethodsTree(state).paymentMethodTypes || [];

export const paymentTypeChosen = (state: RootState): string => paymentMethodsTree(state).paymentTypeChosen || '';
