export interface AppConfiguration {
  APP_URL: string;
  AUTH_SERVICE_URL: string;
  CLIENT_ID: string;
  IS_LOCAL_ENV: boolean;
  CARDINAL_SONGBIRD_URL: string;
  COPAS_URL: string;
  UNIFIED_COMMERCE_URL: string;
  CLASSIC_PRODUCTS_URL: string;
  ADMIN_URL: string;
  COG_URL: string;
  AMPLITUDE: {
    project: string;
    apiKey: string;
    disabled: boolean;
  };
  CYBERSOURCE: {
    flex_url: string;
    fingerprint_url: string;
    org_id: string;
    regions: {
      US: {
        session_id: string;
      };
      GB: {
        session_id: string;
      };
      AU: {
        session_id: string;
      };
      CA: {
        session_id: string;
      };
      EU: {
        session_id: string;
      };
    };
  };
}

export type Environments = 'local' | 'development' | 'rc' | 'staging' | 'production';

type AppConfig = {
  [key in Environments]: AppConfiguration;
};

const AppConfig: AppConfig = {
  local: {
    APP_URL: 'http://localhost:3000',
    AUTH_SERVICE_URL: 'https://authenticationed1.lmiinc.test.expertcity.com',
    CLIENT_ID: '18a8b25a-7678-4d8d-b371-2b19d6e44e09',
    IS_LOCAL_ENV: true,
    CARDINAL_SONGBIRD_URL: 'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js',
    COPAS_URL: 'https://copas-apigateway.dev.purchase.goto.com',
    UNIFIED_COMMERCE_URL: 'https://commerceed.serversdev.getgo.com/unifiedcommerceservice',
    CLASSIC_PRODUCTS_URL: 'https://lmi.dev.3amlabs.net/Purchase/PurchaseGateway.aspx?success=1',
    ADMIN_URL: 'http://localhost:7099',
    COG_URL: 'https://lb0-ggc-gateway-ed-us-west-2.serversdev.getgo.com',
    AMPLITUDE: {
      project: 'ED',
      apiKey: 'e2f97fb80341de0b26efe393e587dc2d',
      disabled: true,
    },
    CYBERSOURCE: {
      flex_url: 'https://testflex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js',
      fingerprint_url: 'https://cdn-east.purchase.goto.com/fp/tags.js',
      org_id: '1snn5n9w',
      regions: {
        US: {
          session_id: 'GETGO_US',
        },
        GB: {
          session_id: 'GETGO_UK',
        },
        AU: {
          session_id: 'GETGO_AU',
        },
        CA: {
          session_id: 'COPAS_CAD',
        },
        EU: {
          session_id: 'LMI_IE',
        },
      },
    },
  },
  development: {
    APP_URL: 'https://payments.ed.goto.com',
    AUTH_SERVICE_URL: 'https://authenticationed1.lmiinc.test.expertcity.com',
    CLIENT_ID: '18a8b25a-7678-4d8d-b371-2b19d6e44e09',
    IS_LOCAL_ENV: false,
    CARDINAL_SONGBIRD_URL: 'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js',
    COPAS_URL: 'https://copas-apigateway.dev.purchase.goto.com',
    UNIFIED_COMMERCE_URL: 'https://commerceed.serversdev.getgo.com/unifiedcommerceservice',
    CLASSIC_PRODUCTS_URL: 'https://lmi.dev.3amlabs.net/Purchase/PurchaseGateway.aspx?success=1',
    ADMIN_URL: 'https://admin.dev.gtc.goto.com',
    COG_URL: 'https://lb0-ggc-gateway-ed-us-west-2.serversdev.getgo.com',
    AMPLITUDE: {
      project: 'ED',
      apiKey: 'e2f97fb80341de0b26efe393e587dc2d',
      disabled: false,
    },
    CYBERSOURCE: {
      flex_url: 'https://testflex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js',
      fingerprint_url: 'https://cdn-east.purchase.goto.com/fp/tags.js',
      org_id: '1snn5n9w',
      regions: {
        US: {
          session_id: 'GETGO_US',
        },
        GB: {
          session_id: 'GETGO_UK',
        },
        AU: {
          session_id: 'GETGO_AU',
        },
        CA: {
          session_id: 'COPAS_CAD',
        },
        EU: {
          session_id: 'LMI_IE',
        },
      },
    },
  },
  rc: {
    APP_URL: 'https://payments.rc.goto.com',
    AUTH_SERVICE_URL: 'https://authenticationed1.lmiinc.test.expertcity.com',
    CLIENT_ID: '62020fe8-c245-494e-a839-03a45f1cffa1',
    IS_LOCAL_ENV: false,
    CARDINAL_SONGBIRD_URL: 'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js',
    COPAS_URL: 'https://copas-rc.serversdev.getgo.com',
    UNIFIED_COMMERCE_URL: 'https://commercerc.serversdev.getgo.com/unifiedcommerceservice/',
    CLASSIC_PRODUCTS_URL: 'https://lmirc.dev.3amlabs.net/Purchase/PurchaseGateway.aspx?success=1',
    COG_URL: 'https://lb0-ggc-gateway-rc-us-west-2.serversdev.getgo.com',
    ADMIN_URL: '',
    AMPLITUDE: {
      project: 'RC',
      apiKey: '',
      disabled: true,
    },
    CYBERSOURCE: {
      flex_url: 'https://testflex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js',
      fingerprint_url: 'https://cdn-east.purchase.goto.com/fp/tags.js',
      org_id: '1snn5n9w',
      regions: {
        US: {
          session_id: 'GETGO_US',
        },
        GB: {
          session_id: 'GETGO_UK',
        },
        AU: {
          session_id: 'GETGO_AU',
        },
        CA: {
          session_id: 'COPAS_CAD',
        },
        EU: {
          session_id: 'LMI_IE',
        },
      },
    },
  },
  staging: {
    APP_URL: 'https://payments.stage.goto.com',
    CLIENT_ID: 'dc2da939-22ad-4059-b908-85b5a63673e0',
    AUTH_SERVICE_URL: 'https://authenticationstage.logmeininc.com',
    IS_LOCAL_ENV: false,
    CARDINAL_SONGBIRD_URL: 'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js',
    COPAS_URL: 'https://copas-stage.servers.getgo.com',
    UNIFIED_COMMERCE_URL: 'https://commercestage.servers.getgo.com/unifiedcommerceservice',
    CLASSIC_PRODUCTS_URL: 'https://lmirc.dev.3amlabs.net/Purchase/PurchaseGateway.aspx?success=1',
    ADMIN_URL: 'https://admin.staging.gtc.goto.com',
    COG_URL: 'https://ggc-stage-us-east-1.servers.getgo.com',
    AMPLITUDE: {
      project: 'Stage',
      apiKey: '',
      disabled: true,
    },
    CYBERSOURCE: {
      flex_url: 'https://testflex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js',
      fingerprint_url: 'https://cdn-east.purchase.goto.com/fp/tags.js',
      org_id: '1snn5n9w',
      regions: {
        US: {
          session_id: 'g2aexpress',
        },
        GB: {
          session_id: 'ecomm_emea',
        },
        AU: {
          session_id: 'ecomm_emea',
        },
        CA: {
          session_id: 'ecomm_emea',
        },
        EU: {
          session_id: 'ecomm_emea',
        },
      },
    },
  },
  production: {
    APP_URL: 'https://payments.goto.com',
    AUTH_SERVICE_URL: 'https://authentication.logmeininc.com',
    CLIENT_ID: '61325a94-7f6f-422e-b901-1f6f2c06d3e7',
    ADMIN_URL: 'https://admin.goto.com',
    IS_LOCAL_ENV: false,
    CARDINAL_SONGBIRD_URL: 'https://songbird.cardinalcommerce.com/edge/v1/songbird.js',
    COPAS_URL: 'https://copas-apigw.servers.getgo.com',
    UNIFIED_COMMERCE_URL: 'https://commerce.servers.getgo.com/unifiedcommerceservice',
    CLASSIC_PRODUCTS_URL: 'https://secure.logmein.com/Purchase/PurchaseGateway.aspx?success=1',
    COG_URL: 'https://ggc-gateway-prod.servers.getgo.com',
    AMPLITUDE: {
      project: 'Live',
      apiKey: 'ec24d4830df418709bc76a8509369fbd',
      disabled: false,
    },
    CYBERSOURCE: {
      flex_url: 'https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js',
      fingerprint_url: 'https://cdn-east.purchase.goto.com/fp/tags.js',
      org_id: 'k8vif92e',
      regions: {
        US: {
          session_id: 'GETGO_US',
        },
        GB: {
          session_id: 'GETGO_UK',
        },
        AU: {
          session_id: 'GETGO_AU',
        },
        CA: {
          session_id: 'COPAS_CAD',
        },
        EU: {
          session_id: 'LMI_IE',
        },
      },
    },
  },
};

export default AppConfig;
