import { RootState } from 'types/root';
import { AddDDResp, TransactionsDDState, UseDDPayResp } from 'types/transaction-dd';
import { COPAS_PAYMENT_STATUS } from 'utils/constants';

export const transactionsDDTree = (state: RootState): TransactionsDDState => state.transactionsDD || {};

export const transactionsDDIsLoading = (state: RootState): boolean => transactionsDDTree(state).isLoading || false;

export const addDDResp = (state: RootState): AddDDResp => transactionsDDTree(state).addDDResp || {};

export const useDDPayResp = (state: RootState): UseDDPayResp => transactionsDDTree(state).useDDPayResp || {};

export const useDDPayFailed = (state: RootState): boolean =>
  useDDPayResp(state).status === COPAS_PAYMENT_STATUS.FAILED || false;

export const useDDPaySuccess = (state: RootState): boolean =>
  useDDPayResp(state).status === COPAS_PAYMENT_STATUS.SUCCESS || false;

export const useDDPayPending = (state: RootState): boolean =>
  useDDPayResp(state).status === COPAS_PAYMENT_STATUS.PENDING || false;
