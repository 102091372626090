import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Checkbox, Option, Select, TextField, Typography } from '@getgo/chameleon-web-react-wrapper';
import { Form, Formik } from 'formik';

import { useAppDispatch, useAppSelector } from 'hooks';
import { showErrorSnack } from 'modules/error';
import { postPaymentMethods, setPaymentType } from 'modules/payment-methods';
import { sessionIntent, sessionSuccessRedirectUrl } from 'modules/session-details';
import Track, { ACHCancelCTA, ACHSaveCTA } from 'modules/tracking';
import { postTransactionAddAch } from 'modules/transactions-ach';
import { AddAchPayload } from 'types/transaction-ach';
import { getAchFormValidationSchema } from 'utils/ach-utils';
import { ACH, achAccountTypeList, COPAS_PAYMENT_INTENT, COPAS_PAYMENT_STATUS } from 'utils/constants';
import st from 'utils/shared-translations';

import './ach-form.css';

const AchForm: FC = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const formInitialValues = {
    accountNumber: '',
    bankTransitNumber: '',
    accountType: intl.formatMessage(st['ach.bank.account.type']),
  };

  const [isAgreedToTnC, setAgreedToTnC] = useState(false);
  const [isDefault, setDefault] = useState(false);

  // Session selectors
  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);
  const selectedSessionIntent = useAppSelector(sessionIntent);

  const handleOnSubmit = (values, { setSubmitting }) => {
    Track(ACHSaveCTA, {});

    const payload: AddAchPayload = {
      ...values,
      setAsDefault: isDefault,
    };

    return dispatch(postTransactionAddAch(payload))
      .unwrap()
      .then((resp) => {
        if (resp.status === COPAS_PAYMENT_STATUS.FAILED) {
          setSubmitting(false);
          dispatch(showErrorSnack(st['alert.error.paymentmethod.general.failure']));
          return;
        }
        if (selectedSessionIntent === COPAS_PAYMENT_INTENT.PAYMENT_OR_PAYMENT_METHOD_CHANGE) {
          dispatch(postPaymentMethods()).then(() => dispatch(setPaymentType(ACH)));
        } else {
          window.location.assign(selectedSuccessRedirectUrl);
        }
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(showErrorSnack(st['alert.error.paymentmethod.general.failure']));
      });
  };

  const handleCancel = () => {
    Track(ACHCancelCTA, {});
    if (selectedSessionIntent === COPAS_PAYMENT_INTENT.PAYMENT_OR_PAYMENT_METHOD_CHANGE) {
      dispatch(setPaymentType(''));
    } else {
      window.location.assign(selectedSuccessRedirectUrl);
    }
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={getAchFormValidationSchema(intl)}
      onSubmit={handleOnSubmit}
    >
      {({ values, isValid, errors, handleChange, isSubmitting, dirty, touched, setFieldTouched }) => (
        <Form spellCheck={false}>
          <div className="ach-form__input">
            <TextField
              name="accountNumber"
              fieldsize="medium"
              value={values.accountNumber}
              onBlur={() => setFieldTouched('accountNumber', true)}
              onChange={handleChange}
              helperText={touched.accountNumber && errors.accountNumber}
              error={touched.accountNumber && !!errors.accountNumber}
              fullwidth
              labelId="account-number"
              disabled={isSubmitting}
            >
              {intl.formatMessage(st['ach.bank.account.number'])}
            </TextField>
            <TextField
              name="bankTransitNumber"
              fieldsize="medium"
              value={values.bankTransitNumber}
              onBlur={() => setFieldTouched('bankTransitNumber', true)}
              onChange={handleChange}
              helperText={touched.bankTransitNumber && errors.bankTransitNumber}
              error={touched.bankTransitNumber && !!errors.bankTransitNumber}
              fullwidth
              labelId="routing-number"
              disabled={isSubmitting}
            >
              {intl.formatMessage(st['ach.bank.routing.number'])}
            </TextField>
            <Select
              name="accountType"
              label={intl.formatMessage(st['ach.bank.account.type'])}
              value={values.accountType}
              onBlur={() => setFieldTouched('accountType', true)}
              onChange={handleChange}
              helperText={touched.accountType ? errors.accountType : ''}
              error={touched.accountType && !!errors.accountType}
              fullwidth
              size="medium"
              disabled={isSubmitting}
              selectedValue={<span>{values.accountType}</span>}
            >
              <Option value={intl.formatMessage(st['ach.bank.account.type'])}>
                {intl.formatMessage(st['ach.bank.account.type'])}
              </Option>
              {achAccountTypeList.map((accountType: string) => (
                <Option key={accountType} value={accountType}>
                  {accountType}
                </Option>
              ))}
            </Select>
          </div>
          <Checkbox
            className="ach-form__tnc-checkbox"
            required
            disabled={isSubmitting}
            checked={isAgreedToTnC}
            onChange={() => setAgreedToTnC(!isAgreedToTnC)}
          >
            <Typography variant="body-small">{intl.formatMessage(st['ach.term.condition'])}</Typography>
          </Checkbox>
          <div className="ach-form__ctas">
            <Checkbox disabled={isSubmitting} onClick={() => setDefault(!isDefault)} checked={isDefault}>
              {intl.formatMessage(st['payment.method.set.as.default'])}
            </Checkbox>
            <div>
              <Button
                className="ach-form__cancel-cta"
                variant="tertiary"
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                {intl.formatMessage(st['ach.form.cta.cancel'])}
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!isAgreedToTnC || !isValid || isSubmitting || !dirty}
                isLoading={isSubmitting}
              >
                {intl.formatMessage(st['ach.form.cta.add'])}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AchForm;
